import React from "react";
import Styles from "./TextMarquee.module.scss";
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface TextMarqueeProps {
    text: string;
}

const TextMarquee = ({ text }: TextMarqueeProps) => {
    return (
        <div className={Styles.marquee}>
            <Typography size="xs" weight="medium" color={COLORS.GREY_700}>
                {text}
            </Typography>
        </div>
    );
};

export default TextMarquee;
